import { useRouter } from 'next/router';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getCouponPrices } from '@/modules/couponLink/services/couponLink';
import { ProductPriceInfo } from '@/modules/product/types/product';

export const COUPON_COOKIE = 'couponLink';

export const getCouponLinkCookie = () => parseCookies()[COUPON_COOKIE];

export const hasCouponLinkCookie = () => Boolean(getCouponLinkCookie());

export const setCouponLinkCookie = (coupon: string) =>
  setCookie(null, COUPON_COOKIE, coupon, { path: '/' });

export const destroyCouponLinkCookie = () =>
  destroyCookie(null, COUPON_COOKIE, { path: '/' });

export const useAsyncCouponLink = (skus: string[]) => {
  const { query } = useRouter();
  const queryCoupon = query.couponId as string | undefined;
  const couponCookie = getCouponLinkCookie();
  const couponId = queryCoupon || couponCookie;
  const hasSku = skus.length > 0 && skus.every(Boolean);

  useEffect(() => {
    if (queryCoupon && queryCoupon !== couponCookie) {
      setCouponLinkCookie(queryCoupon);
    }
  }, [queryCoupon, couponCookie, couponId]);

  const queryKey = [COUPON_COOKIE, skus.join(','), query.couponId];
  const hasCouponId = Boolean(couponId);

  const { data, isLoading } = useQuery(
    queryKey,
    () => getCouponPrices(skus, couponId),
    {
      refetchOnMount: false,
      enabled: Boolean(couponId) && hasSku,
    },
  );

  const getCouponPriceInfos = (
    modelColor: string,
  ): ProductPriceInfo | undefined =>
    data?.find(({ sku }) => sku.includes(modelColor))?.priceInfos;

  return {
    isLoading: hasCouponId && isLoading,
    hasCouponLink: hasSku && hasCouponId && Boolean(data),
    couponData: data,
    couponId,
    getCouponPriceInfos,
  };
};
